function r(t, e) {
  if (t.length < 2)
    throw new Error(`Could not get allocation for ${t}`);
  const n = 1 / t.length * e * 100, o = 1 / t.length * (e + 1) * 100;
  return [n, o];
}
function a(t) {
  const e = {};
  if (!t.includes("A-control"))
    throw Error("No control group found with name 'A-control'");
  for (const [n, o] of t.entries())
    e[o] = {
      allocation: r(t, n)
    };
  return e;
}
const _ = {
  // CHECKOUT ------------------------------------------------------------------
  "abtest_dba_2980-dba_5": {
    scope: "local",
    description: "New checkout summary design",
    variants: a(["A-control", "B-new"]),
    domain_and_index: "abtest_name_dba_5",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3091-dba_2": {
    scope: "local",
    description: "Show payment options in cart",
    variants: a(["A-control", "B-options"]),
    domain_and_index: "abtest_name_dba_2",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3160_1-dba_4": {
    scope: "local",
    description: "Points redemption by default ON",
    variants: a(["A-control", "B-redemption"]),
    domain_and_index: "abtest_name_dba_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_2980-dba_6": {
    scope: "local",
    description: "Cart summary position desktop",
    variants: a(["A-control", "B-sticky"]),
    domain_and_index: "abtest_name_dba_6",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3217-dba_8": {
    scope: "local",
    description: "Error message only after typing",
    variants: a(["A-control", "B-dirty"]),
    domain_and_index: "abtest_name_dba_8",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3220-dba_9": {
    scope: "local",
    description: "Show free pickup or delivery in cart summary",
    variants: a(["A-control", "B-show"]),
    domain_and_index: "abtest_name_dba_9",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // MYACCOUNT -----------------------------------------------------------------
  "abtest_drl_2343-drl_1": {
    scope: "local",
    description: "Reward commercial optin for NEW accounts with 500 loyalty points",
    variants: a(["A-control", "B-new"]),
    domain_and_index: "abtest_name_drl_1",
    formula: {
      gamma_nl: !0,
      gamma_be: !1,
      karwei_nl: !1
    }
  },
  "abtest_drl_3287-drl_2": {
    scope: "local",
    description: "Show advantages of creating an account",
    variants: a(["A-control", "B-advantages"]),
    domain_and_index: "abtest_name_drl_2",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // SHOPFRONT -----------------------------------------------------------------
  "abtest_xtd_autosug-ew_4": {
    scope: "local",
    description: "Extend autosuggest/autocomplete with categories filter",
    variants: a(["A-control", "B-six-sug", "C-incl-cat"]),
    domain_and_index: "abtest_name_ew_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  }
};
export {
  _ as experiments
};
