import { IntergammaToastContainer } from "@intergamma/global-styling"
import { useCartQuery } from "api/cart"
import { useCurrentCustomerQuery } from "api/current-customer"
import { Footer } from "components/Footer"
import { Header } from "components/Header"
import { Main } from "components/Main"
import { Page } from "components/Page"
import { MopinionFeedback } from "features/mopinion/components"
import { lazy, useEffect } from "react"
import { useSearchParams } from "react-router"

const Cart = lazy(() =>
  import(/* webpackChunkName: "cart-page" */ "features/cart/components/Cart").then((module) => ({
    default: module.Cart,
  }))
)

export function CartPage() {
  const cartQuery = useCartQuery()
  const currentCustomerQuery = useCurrentCustomerQuery()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const requestedCartUid = searchParams.get("cartUid")

    if (cartQuery.data && requestedCartUid && requestedCartUid !== cartQuery.data?.uid) {
      // Remove cartUid, since we got a new one
      searchParams.delete("cartUid")
      setSearchParams(searchParams)
    }
  }, [cartQuery.data, searchParams, setSearchParams])

  if (!cartQuery.isSuccess || !currentCustomerQuery.isSuccess) {
    return null
  }

  return (
    <Page moduleName="cart">
      <Header />
      <IntergammaToastContainer />
      <Main>
        <Cart />
        <MopinionFeedback className="mt-6" />
      </Main>
      <Footer />
    </Page>
  )
}
