import { sanitizeUrl } from "@braintree/sanitize-url"
import { sessionStorage } from "@intergamma/storage"
import type { CartResponse } from "features/cart/types"
import type { FormulaCountry } from "features/checkout/types"
import { useMemo } from "react"
import { useSearchParams } from "react-router"
import type { IMultipartPaymentData } from "types"

export interface MultipartPaymentData {
  cartUid: CartResponse["uid"]
  billingCountry: FormulaCountry
  amountRemaining: number
  multiPartReference?: IMultipartPaymentData["multiPartReference"]
}

export function useMultipartPaymentData() {
  const [params] = useSearchParams()

  return useMemo(() => {
    if (!["amountRemaining", "billingCountry", "cartUid"].every((item) => params.has(item))) {
      return
    }

    const multiPartReference = sanitizeUrl(params.get("multiPartReference") ?? params.get("paymentData") ?? "")

    return {
      amountRemaining: Number(sanitizeUrl(params.get("amountRemaining") ?? "")),
      billingCountry: sanitizeUrl(params.get("billingCountry") ?? ""),
      cartUid: sanitizeUrl(params.get("cartUid") ?? ""),
      expectedDelivery: sessionStorage.getJson(multiPartReference)?.expectedDelivery,
      multiPartReference,
    } as MultipartPaymentData
  }, [params])
}
