import "core-js/features/string/replace-all"
import "core-js/stable"

import { datadogLogs } from "@datadog/browser-logs"
import { datadogRum } from "@datadog/browser-rum"
import { configureAuth } from "@intergamma/account"
import { dispatchAdobeEvent, waitForGALoad } from "@intergamma/adobe-tracking"
import { isSyntheticTest } from "@intergamma/common"
import { ConfigProvider } from "@intergamma/config"
import { experiments } from "@intergamma/experiments"
import { QueryClientProvider } from "@tanstack/react-query"
import { initializeDatadogLogs } from "config/datadog-logs"
import { initializeDatadogRum } from "config/datadog-rum"
import { formulaConfig } from "config/formula"
import { globalConfig } from "config/global"
import { initializeI18next } from "config/i18n"
import { queryClient } from "config/query"
import { DeviceContextProvider } from "context/device"
import { MotionConfig } from "framer-motion"
import { log } from "lib/datadog-logging"
import { Suspense } from "react"
import { createRoot } from "react-dom/client"
import Modal from "react-modal"
import { BrowserRouter } from "react-router"
import { App } from "./App"
import "./main.css"
import "./modal.css"

window.addEventListener("unhandledrejection", (error) =>
  log.error(`Unhandled Promise rejection: ${error.reason}`, {}, error instanceof Error ? error : undefined)
)

const rootElement = document.getElementById("root")!
const root = createRoot(rootElement)
Modal.setAppElement(rootElement)

configureAuth(formulaConfig.id)
initializeDatadogLogs()
initializeDatadogRum()
initializeI18next()

// Send abtest event for already bucketed experiments
Object.entries(experiments.active).forEach(([name, experiment]) => {
  dispatchAdobeEvent({
    type: "abtest",
    data: {
      abtest_name: name,
      abtest_variant: experiment.variant ?? "do-not-track",
      domain_and_index: experiment.domain_and_index,
    },
  })
})

datadogLogs.setGlobalContextProperty("experiments", experiments.active)
datadogRum.setGlobalContextProperty("experiments", experiments.active)

experiments.configure({
  dev: globalConfig.isLocalhost || isSyntheticTest,
  formula: formulaConfig,
  async onCoinflip(name, experiment, variant) {
    datadogLogs.setGlobalContextProperty("experiments", experiments.active)
    datadogRum.setGlobalContextProperty("experiments", experiments.active)

    await waitForGALoad()

    dispatchAdobeEvent({
      type: "abtest",
      data: {
        abtest_name: name,
        abtest_variant: typeof variant === "string" ? variant : "do-not-track",
        domain_and_index: experiment.domain_and_index,
      },
    })
  },
})

if (!globalConfig.isLocalhost && "serviceWorker" in navigator) {
  navigator.serviceWorker.register("/n/sw.js")
}

// Set theme
document.documentElement.classList.add(formulaConfig.brand === "karwei" ? "theme-karwei" : "theme-gamma")

root.render(
  <Suspense fallback={null}>
    <ConfigProvider config={{ ...formulaConfig, algoliaApplicationId: globalConfig.algoliaApplicationId }}>
      <BrowserRouter basename="/n/">
        <DeviceContextProvider>
          <QueryClientProvider client={queryClient}>
            <MotionConfig reducedMotion="user">
              <App />
            </MotionConfig>
          </QueryClientProvider>
        </DeviceContextProvider>
      </BrowserRouter>
    </ConfigProvider>
  </Suspense>
)
