import { useTrackLoggedIn } from "@intergamma/account"
import { GlobalToastStyling } from "@intergamma/global-styling"
import { IntergammaTheme } from "@intergamma/theme-next"
import { useSessionDataQuery } from "api/myaccount/session-data"
import ScrollToTop from "components/ScrollToTop"
import { formulaConfig } from "config/formula"
import { useIsDebugEnabled } from "hooks/useIsDebugEnabled"
import { useMultiLanguageRedirectToLanguageURL } from "hooks/useMultiLanguageRedirectToLanguageURL"
import { usePagesConfig } from "hooks/usePagesConfig"
import { useSetLanguageCookieForBackend } from "hooks/useSetLanguageCookieForBackend"
import { lazy } from "react"
import { useRoutes } from "react-router"

const Devtools = lazy(() =>
  import(/* webpackChunkName: "devtools" */ "./Devtools").then((module) => ({
    default: module.Devtools,
  }))
)

export function App() {
  const isDebugEnabled = useIsDebugEnabled()
  const pages = usePagesConfig()
  const outlet = useRoutes(Object.values(pages))
  const sessionDataQuery = useSessionDataQuery()

  useSetLanguageCookieForBackend()
  useMultiLanguageRedirectToLanguageURL()
  useTrackLoggedIn({
    formulaId: formulaConfig.id,
    customerId: sessionDataQuery.data?.uid,
    hashedId: sessionDataQuery.data?.hashedId,
  })

  return (
    <IntergammaTheme formula={formulaConfig.id}>
      <GlobalToastStyling headerHeight={0} $sticky="xs" />
      <ScrollToTop />
      {outlet}
      {isDebugEnabled && <Devtools />}
    </IntergammaTheme>
  )
}
