import { supportedLanguages } from "config/formula"
import { hasLanguageCode } from "helpers/i18n"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router"

export function useMultiLanguageRedirectToLanguageURL() {
  const location = useLocation()
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (supportedLanguages.length > 1 && !hasLanguageCode(location.pathname)) {
      const pathnameWithLanguage = `${i18n.language.substring(0, 2)}${location.pathname}${location.search}`

      navigate(pathnameWithLanguage, { replace: true })
    }
  }, [location, navigate, i18n.language])
}
