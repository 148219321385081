import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import { dispatchMobileAppEvent } from "@intergamma/mobile-app"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import { QUERY_KEY as QUERY_KEY_TRACK_VIEW_CART } from "api/tracking"
import { useDispatchTrackCartMutation } from "features/cart/hooks/tracking"
import type { CartMutationResponse, CartResponse, CartResponseService, MappedCartResponse } from "features/cart/types"
import { flattenToCart } from "helpers/flattenToCart"
import { getCartWithAdjustedQuantity } from "helpers/getCartWithAdjustedQuantity"
import { mapCart } from "helpers/mapCart"
import { log } from "lib/datadog-logging"
import { useSearchParams } from "react-router"
import type { ViewCartDispatchEventData } from "./generated/checkout"
import { QUERY_KEY_NUMBER_OF_PRODUCTS } from "./number-of-products"

export const QUERY_KEY_CART = "cart"

interface UseCartQueryOptions {
  enabled?: boolean
}
export function useCartQuery({ enabled = true }: UseCartQueryOptions = {}) {
  const [searchParams] = useSearchParams()
  const cartUid = searchParams.get("cartUid") ?? undefined

  return useQuery({
    enabled,
    queryKey: [QUERY_KEY_CART],
    async queryFn({ signal }) {
      let url = "/api/v2/cart"

      if (cartUid) {
        url += `/${cartUid}`
      }

      const { data } = await api.get<CartResponse>(url, { signal })

      return mapCart(data)
    },
  })
}

export function useConnectLoyaltyCardToCartMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn(loyaltyCardNumber: string) {
      log.info("Attaching loyalty card number", { loyaltyCardNumber })
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/loyalty/card-number/${loyaltyCardNumber}`)

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)

      dispatchAdobeEvent({ type: "loyalty_card_added_to_cart", data: { is_loyalty_enabled: "true" } })
    },
  })
}

export function useApplyActionCodeMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn(actionCode: string) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/actioncodes/${actionCode}`)

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
  })
}

export function useRemoveActionCodeMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn(actionCode: string) {
      const { data } = await api.delete<CartMutationResponse>(`/api/v2/cart/actioncodes/${actionCode}`)

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
  })
}

export function useDeleteCartValidationErrorsMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn() {
      return api.delete("/api/v2/cart/validationerrors")
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_CART] })
    },
  })
}

export function useApplyLoyaltyPointsMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn(isEnabled: boolean) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/loyalty/usepoints/${isEnabled}`)

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
  })
}

export function useAddEntryBySkuMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ["add-entry"],
    async mutationFn({ sku, desiredQuantity }: { sku: string; desiredQuantity: number }) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/entries/${sku}`, {
        desiredQuantity,
        matchMaxStockIfExceeds: true,
      })

      return mapCart(flattenToCart(data))
    },
    onSuccess(cart) {
      queryClient.setQueryData([QUERY_KEY_CART], cart)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })
    },
  })
}

export const MUTATION_KEY_UPDATE_ENTRY_QUANTITY = "update-entry-quantity"
export function useUpdateEntryQuantityBySkuMutation() {
  const queryClient = useQueryClient()
  const trackCartMutation = useDispatchTrackCartMutation()

  return useMutation({
    mutationKey: [MUTATION_KEY_UPDATE_ENTRY_QUANTITY],
    async mutationFn({ desiredQuantity, sku }: { desiredQuantity: number; sku: string }) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/entries/${sku}`, {
        desiredQuantity,
        matchMaxStockIfExceeds: true,
      })

      return mapCart(flattenToCart(data))
    },
    onMutate(params) {
      const oldViewCart = queryClient.getQueryData<ViewCartDispatchEventData>([QUERY_KEY_TRACK_VIEW_CART])
      const oldCart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])

      queryClient.setQueryData<MappedCartResponse | undefined>([QUERY_KEY_CART], (old) =>
        getCartWithAdjustedQuantity(old, params)
      )

      return { oldCart, oldViewCart }
    },
    onSuccess(cart, variables, context) {
      const oldViewCart = context?.oldViewCart
      const oldCart = context?.oldCart
      queryClient.setQueryData([QUERY_KEY_CART], cart)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })
      const oldEntry = oldCart?.entries.find((entry) => entry.sku === variables.sku)
      const updatedEntry = cart.entries.find((entry) => entry.sku === variables.sku)

      if (!oldEntry || !updatedEntry) {
        throw new Error(`Cart entry mutation could not be determined (SKU=${variables.sku})`)
      }

      dispatchMobileAppEvent({
        app: "cart_checkout",
        type: variables.desiredQuantity < oldEntry.quantity ? "IG_REMOVE_FROM_CART" : "IG_ADD_TO_CART",
        payload: {
          state: "success",
          productId: variables.sku,
          amount: updatedEntry.quantity,
          numberOfProducts: cart.cartItemCount,
        },
      })

      if (updatedEntry.quantity < oldEntry.quantity) {
        trackCartMutation("remove_from_cart", {
          quantity: oldEntry.quantity - updatedEntry.quantity,
          sku: variables.sku,
          oldViewCart,
        })
      }

      if (updatedEntry.quantity > oldEntry.quantity) {
        trackCartMutation("add_to_cart", {
          quantity: updatedEntry.quantity - oldEntry.quantity,
          sku: variables.sku,
          oldViewCart,
        })
      }
    },
    onError(error, variables, context) {
      log.error(
        `Failed to update cart entry quantity (SKU=${variables.sku})`,
        {},
        error instanceof Error ? error : undefined
      )

      queryClient.setQueryData([QUERY_KEY_CART], context?.oldCart)

      const cart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])
      const entry = cart?.entries.find((entry) => entry.sku === variables.sku)

      if (!entry) {
        throw new Error(`Cart entry could not be found (SKU=${variables.sku})`)
      }

      dispatchMobileAppEvent({
        app: "cart_checkout",
        type: variables.desiredQuantity < entry.quantity ? "IG_REMOVE_FROM_CART" : "IG_ADD_TO_CART",
        payload: {
          state: "failed",
          productId: variables.sku,
          amount: entry.quantity,
          numberOfProducts: cart?.cartItemCount ?? 0,
        },
      })
    },
  })
}

export const MUTATION_KEY_UPDATE_ENTRY_SERVICE = "update-entry-service"
export function useUpdateEntryServiceBySkuMutation() {
  const queryClient = useQueryClient()
  const trackCartMutation = useDispatchTrackCartMutation()

  return useMutation({
    mutationKey: [MUTATION_KEY_UPDATE_ENTRY_SERVICE],
    async mutationFn({ service, sku }: { service: CartResponseService; sku: string }) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/entries/${sku}/services/${service.id}`)

      return mapCart(flattenToCart(data.cart))
    },
    onMutate() {
      const oldViewCart = queryClient.getQueryData<ViewCartDispatchEventData>([QUERY_KEY_TRACK_VIEW_CART])
      return { oldViewCart }
    },
    onSuccess(cart, variables, context) {
      const oldViewCart = context?.oldViewCart
      queryClient.setQueryData([QUERY_KEY_CART], cart)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })
      const updatedEntry = cart.entries.find((entry) => entry.sku === variables.sku)

      if (!updatedEntry) {
        throw new Error(`Cart entry mutation for added service could not be determined (SKU=${variables.sku})`)
      }

      trackCartMutation("add_to_cart", {
        quantity: updatedEntry.quantity,
        sku: variables.sku,
        oldViewCart,
      })
    },
    onError(error, variables) {
      log.error(
        `Failed to update cart entry (SKU=${variables.sku}) with service (SERVICE_ID=${variables.service.id})`,
        {},
        error instanceof Error ? error : undefined
      )

      const cart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])
      const entry = cart?.entries.find((entry) => entry.sku === variables.sku)

      if (!entry) {
        throw new Error(`Cart entry could not be found (SKU=${variables.sku})`)
      }
    },
  })
}

export const MUTATION_KEY_REMOVE_ENTRY_SERVICE = "remove-entry-service"
export function useRemoveEntryServiceBySkuMutation() {
  const queryClient = useQueryClient()
  const trackCartMutation = useDispatchTrackCartMutation()

  return useMutation({
    mutationKey: [MUTATION_KEY_REMOVE_ENTRY_SERVICE],
    async mutationFn({ service, sku }: { service: CartResponseService; sku: string }) {
      const { data } = await api.delete<CartMutationResponse>(`/api/v2/cart/entries/${sku}/services/${service.id}`)

      return mapCart(flattenToCart(data.cart))
    },
    onMutate() {
      const oldViewCart = queryClient.getQueryData<ViewCartDispatchEventData>([QUERY_KEY_TRACK_VIEW_CART])
      return { oldViewCart }
    },
    onSuccess(cart, variables, context) {
      const oldViewCart = context?.oldViewCart
      queryClient.setQueryData([QUERY_KEY_CART], cart)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })
      const updatedEntry = cart.entries.find((entry) => entry.sku === variables.sku)

      if (!updatedEntry) {
        throw new Error(`Cart entry mutation for removed service could not be determined (SKU=${variables.sku})`)
      }

      trackCartMutation("remove_from_cart", {
        quantity: updatedEntry.quantity,
        sku: variables.sku,
        oldViewCart,
      })
    },
    onError(error, variables) {
      log.error(
        `Failed to remove service (SERVICE_ID=${variables.service.id}) from cart entry (SKU=${variables.sku})`,
        {},
        error instanceof Error ? error : undefined
      )

      const cart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])
      const entry = cart?.entries.find((entry) => entry.sku === variables.sku)

      if (!entry) {
        throw new Error(`Cart entry could not be found (SKU=${variables.sku}) to update (SERVICE_ID=${variables.sku})`)
      }
    },
  })
}

export const MUTATION_KEY_REMOVE_ENTRY = "remove-entry"
export function useRemoveEntryBySkuMutation() {
  const queryClient = useQueryClient()
  const trackCartMutation = useDispatchTrackCartMutation()

  return useMutation({
    mutationKey: [MUTATION_KEY_REMOVE_ENTRY],
    async mutationFn({ sku }: { sku: string }) {
      const { data } = await api.delete<CartMutationResponse>(`/api/v2/cart/entries/${sku}`)

      return mapCart(flattenToCart(data))
    },
    onMutate(options) {
      const oldViewCart = queryClient.getQueryData<ViewCartDispatchEventData>([QUERY_KEY_TRACK_VIEW_CART])
      const oldCart = queryClient.getQueryData<MappedCartResponse>([QUERY_KEY_CART])!

      queryClient.setQueryData([QUERY_KEY_CART], {
        ...oldCart,
        entries: oldCart.entries.filter((entry) => entry.sku !== options.sku),
      })

      return { oldViewCart, oldCart }
    },
    onSuccess(cart, variables, context) {
      const oldViewCart = context?.oldViewCart
      const oldCart = context?.oldCart
      const updatedEntry = oldCart?.entries.find((entry) => entry.sku === variables.sku)

      queryClient.setQueryData([QUERY_KEY_CART], cart)
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS] })

      dispatchMobileAppEvent({
        app: "cart_checkout",
        type: "IG_REMOVE_FROM_CART",
        payload: {
          state: "success",
          productId: variables.sku,
          amount: 0,
          numberOfProducts: cart.cartItemCount,
        },
      })

      if (!updatedEntry) {
        throw new Error(`Cart entry mutation for removed entry could not be determined (SKU=${variables.sku})`)
      }

      trackCartMutation("remove_from_cart", {
        quantity: updatedEntry.quantity,
        sku: variables.sku,
        oldViewCart,
      })
    },
    onError(error, variables, context) {
      log.error(`Failed to remove cart entry (SKU=${variables.sku})`, {}, error instanceof Error ? error : undefined)

      queryClient.setQueryData([QUERY_KEY_CART], context?.oldCart)

      const entry = context?.oldCart.entries.find((entry) => entry.sku === variables.sku)

      if (!entry) {
        throw new Error(`Cart entry could not be found (SKU=${variables.sku})`)
      }

      dispatchMobileAppEvent({
        app: "cart_checkout",
        type: "IG_REMOVE_FROM_CART",
        payload: {
          state: "failed",
          productId: variables.sku,
          amount: entry.quantity,
          numberOfProducts: context?.oldCart.cartItemCount ?? 0,
        },
      })
    },
  })
}

export function useApplyStickerBySkuAndBarcodeMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn({ barcode, sku }: { barcode: string; sku: string }) {
      const { data } = await api.put<CartMutationResponse>(`/api/v2/cart/entries/${sku}/stickers/${barcode}`)

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
    onError(error) {
      log.error("Failed to apply a sticker discount", {}, error instanceof Error ? error : undefined)
    },
  })
}

export function useToggleActionCodeMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn({ actionCode, isApplied }: { actionCode: string; isApplied: boolean }) {
      const { data } = await api.request<CartMutationResponse>({
        url: `/api/v2/cart/actioncodes/${actionCode}`,
        method: isApplied ? "DELETE" : "PUT",
      })

      return mapCart(flattenToCart(data))
    },
    onSuccess(data) {
      queryClient.setQueryData([QUERY_KEY_CART], data)
    },
    onError(error) {
      log.error("Failed to apply a sticker discount", {}, error instanceof Error ? error : undefined)
    },
  })
}
